import React from "react";

class Travel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image_width: 2048,
            image_height: 1357,
            left: -720,
            width: 2677,
            height: 1174,
            top: 0
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {

        let d = document;
        let documentElement = d.documentElement;
        let e = d.getElementById('travel-bg-image');
        let e_width = e.clientWidth || window.innerWidth || documentElement.clientWidth ;
        let e_height = e.clientHeight|| window.innerHeight|| documentElement.clientHeight ;

        let height = e_height;
        let width = height / this.state.image_height * this.state.image_width
        let left = Math.abs((e_width - this.state.image_width) / 2)

        this.setState(prevState => ({
          height: height,
          width: width,
          left: -left,
          top: 0
        }));

    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

render() {
    return (

  <div id="accommodations-section">
    <div className="index-section-wrapper ">

            <div id="travel-bg-image" className="section-bg-image">
              <img alt="Wisconsin_State_Capitol_Building_during_Tulip_Festival.jpg" src="./images/Wisconsin_State_Capitol_Building_during_Tulip_Festival.jpg"
                style={{fontSize: 0 +'px',  left: this.state.left + 'px', top: this.state.top + 'px', width: this.state.width + 'px', height: this.state.height + 'px', position: 'relative'}} />
            </div>
            <div className="bg-image-overlay">
            </div>
        <div className="accommodations" id="accommodations-page">
          <div className="accommodations-content">


<div id="accomondations-header" >
<h1 className="travel-title">Where to Stay</h1>
<h3 className="text-align-center">Group rates are available at Three hotels<br/>IN DOWNToWN Madison</h3>
<p className="text-align-center">All three hotels are located in the heart of downtown Madison,&nbsp;in walking distance to shops, restaurants, and the Capitol Building.&nbsp;They all have indoor pools, so bring your suit and&nbsp;trunks.</p>
<p className="text-align-center">
<strong>NOTE</strong>: Group rates expired on June 17 but you can still make reservations at any of the three hotels listed below. Please email EO at <strong>
<em>eoconn@yahoo.com</em>
</strong> if you weren&#39;t able to make a reservation using the wedding block so we have your location for delivery of our hotel welcome bags.&nbsp;<strong>The buses to the party are leaving from the <em>Hyatt Place</em> at</strong> <strong>3:15 sharp Saturday afternoon</strong>.</p>
</div>


<div id="hyatt-and-doubletree">

<div id="hyattmadison" className="hotel-content">
<p className="text-align-center">
<strong>
<em>Hyatt Place Madison Downtown</em>
</strong>
<br/>333 West Washington Avenue<br/>Madison, WI&nbsp;53703<br/>(608) 257-2700</p>
<p className="text-align-center">$159/night</p>


<div className="button_container">
    <a  className="booking_button" href="https://www.hyatt.com/en-US/hotel/wisconsin/hyatt-place-madison-downtown/msnzd" target="_blank" rel="noopener noreferrer">Booking&nbsp;Website</a>
</div>
</div>



<div id="doubletreeMadison" className="hotel-content">
<p className="text-align-center">
<strong>
<em>DoubleTree Madison</em>
</strong>
<br/>525 West Johnson Street<br/>Madison, WI 53703<br/>(608) 251-5511</p>
<p className="text-align-center">$159/night</p>

<div className="button_container">
    <a className="booking_button" href="http://doubletree3.hilton.com/en/hotels/wisconsin/doubletree-by-hilton-hotel-madison-MSNDTDT/index.html" target="_blank" rel="noopener noreferrer">Booking&nbsp;Website</a>
</div>
</div>


</div>


<div id="concoursehotel" className="hotel-content">
<p className="text-align-center">
<strong>
<em>The Madison Concourse Hotel</em>
</strong>
<br/>
<span>1 West Dayton Street</span>
<br/>
<span>Madison, WI&nbsp;53703</span>
<br/>
<span>(608) 257-6000</span>
</p>
<p className="text-align-center">
<span>$164+/night</span>
</p>

<div className="button_container">
    <a className="booking_button" href="http://www.concoursehotel.com/"  target="_blank" rel="noopener noreferrer">RESERVE&nbsp;BY&nbsp;PHONE</a>
</div>
</div>

<div id="msn-info" >
<h1 className="text-align-center">Getting There</h1>
<ul dir="ltr">
<li>Dane County Regional Airport&nbsp;(<strong>MSN</strong>) is about 10 minutes from downtown Madison.</li>
<li>
<strong>Chicago</strong> airports are&nbsp;about 2.5 hours from downtown Madison.</li>
<li>Milwaukee (<strong>MKE</strong>)&nbsp;is about 90 minutes from downtown Madison.</li>
</ul>
<p className="text-align-center">
<br/>Each hotel has a&nbsp;<em>free airport shuttle</em>&nbsp;from the <span>Dane County Regional</span>&nbsp;Airport,&nbsp;so renting a car might not be necessary&nbsp;if you&#39;re flying into Madison&nbsp;and planning on staying in the downtown area.</p>
<p className="text-align-center">
<strong>Buses to&nbsp;and from the ceremony/party&nbsp;will be provided.</strong>
<br/>Please don&#39;t drive to the farm. There isn&#39;t&nbsp;enough parking.<br/>It&#39;s approximately&nbsp;25 minutes from downtown Madison to Sun Harvest Farm.</p>
<h3 className="text-align-center"><strong>Buses&nbsp;leave from the Hyatt Place at 3:15 sharp</strong></h3>
</div>


          </div>
        </div>

    </div>
  </div>

    )
}
}

export default Travel
