import React from "react";

// eslint-disable-next-line
let header_data = [
        {sectionID: 'ceremony-section', menuText:'The Big Party'},
        {sectionID: 'foodtrucks-section', menuText:'Fun Food Day'},
        {sectionID: 'farm-1-section', menuText:'The "Afters"'},
        {sectionID: 'accommodations-section', menuText:'Travel Info'},
        {sectionID: 'shameless-slideshow-section', menuText:'Thanks'},
];

class WeddingHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            top: 0
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        let headerLogo = document.getElementById('header-logo');
        let htmlTop = document.getElementsByTagName('html')[0].getBoundingClientRect()['top'];
        let headerLogoHeight = headerLogo.getBoundingClientRect()['height'];

        let new_top = 0
        if (-htmlTop < headerLogoHeight) {
          new_top = htmlTop;
        } else {
          new_top = -headerLogoHeight;
        }

        this.setState(prevState => ({
          top: new_top
        }));
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll()
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

render() {
    return (
        <div id="header-section" className="header-section" style={{top: this.state.top + 'px'}}>
  <header id="header">
      <div id="header-logo" className="header-logo">

        <h1><a href="."><img src="./images/saved_resource" alt="EO &amp; DAVE" /></a></h1>

      </div>
        <div id="header-menu" className="header-menu">
          <nav id="header-nav">
            <div className="header-menu-item">
              <a href="#ceremony-section">
                The Big Party
              </a>
            </div>

            <div className="header-menu-item">
              <a href="#foodtrucks-section">
                Fun Food Day
              </a>
            </div>

            <div className="header-menu-item">
              <a href="#farm-1-section">
                The "Afters"
              </a>
            </div>

            <div className="header-menu-item">
              <a href="#accommodations-section">
                Travel Info
              </a>
            </div>

            <div className="header-menu-item">
              <a href="#shameless-slideshow-section">
                Thanks
              </a>
            </div>
          </nav>
        </div>
  </header>
  </div>
    )
  }

}

export default WeddingHeader
