import React from "react";
import Carousel from '../components/carousel.js'

let beach_image_data = [
    {width: 340, height:340, className:'square-image-340', altText:"IMG_6543.JPG", source:"./images/IMG_6543.JPG"},
    {width: 340, height:340, className:'square-image-340', altText:"BBClarke2.jpg", source:"./images/BBClarke2.jpg"},
    {width: 340, height:340, className:'square-image-340', altText:"BBClarke.png", source:"./images/BBClarke.png"}
]

class Afters extends React.Component {

render() {
    return (

  <div id="farm-1-section">
    <div className="index-section-wrapper">
        <div className="simplify" >
            <h1>The &#34;Afters&#34;</h1>
            <p>In Ireland, the festivities that continue <strong>after the wedding meal</strong> are referred to as the &#34;afters.&#34; Once the buses take our sorry asses back downtown, we&#39;ll clean ourselves up a bit and head
                to <a target="_blank" href="http://www.brocach.com/" rel="noopener noreferrer">Brocach Pub</a><strong> </strong>(Gaelic for <em>Badger Den, pronounced Brokaw</em>),&nbsp;right on the Capitol Square (<span style={{fontSize:14 + 'px'}}>
                <a target="_blank" href="https://www.google.com/maps/place/7+W+Main+St,+Madison,+WI+53703/@43.073558,-89.38359,17z/data=!4m7!1m4!3m3!1s0x8806533edddbfe83:0x853b3b94cc564435!2s7+W+Main+St,+Madison,+WI+53703!3b1!3m1!1s0x8806533edddbfe83:0x853b3b94cc564435" rel="noopener noreferrer">7 West Main Street</a>)</span>. We&#39;d love for you to join.</p><p><strong>Sunday at noon</strong>, if you want to wake yourself up in Lake Monona, we&#39;ll be heading there for some swimming at
                the <a target="_blank" href="http://www.cityofmadison.com/parks/parks/park.cfm?id=1128" rel="noopener noreferrer">B.B. Clarke Beach Park</a>&nbsp;(
                <a target="_blank" href="https://www.google.com/maps/place/B.B.+Clarke+Beach/@43.0777022,-89.3686737,17z/data=!3m1!4b1!4m2!3m1!1s0x88065372edcc4af3:0x3b9a8b4c0094e1ec" rel="noopener noreferrer">835 Spaight St</a>).&nbsp;It&#39;s only a 15-minute walk (0.8 mile)&nbsp;from the Capitol. You can also ask the hotel to take you there since it&#39;s within their free, 2-mile shuttle service radius.&nbsp;Bring some snacks but no &#39;hair of the dog.&#39; It&#39;s a public beach. But it&#39;s mostly grass.&nbsp;It&#39;s the perfect place to chill in the shade or the sun after a long night of gettin&#39; down with your bad self.&nbsp;There are restrooms and water fountains (bubblers), but no food for sale. <em>Weather permitting ... we&#39;ll leave a notice with each hotel&#39;s reception desk and update this website.&nbsp;</em></p>

            <Carousel data={beach_image_data} image_width={340} images_on_screen={3} transition_time={5000}
                    max_width={1020} carousel_height={340} extra_width={0}>
            </Carousel>
        </div>
    </div>
  </div>

    )
}
}

export default Afters
