import React from "react";
import Carousel from '../components/carousel.js'


let party_image_data = [
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/saved_resource(1)'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6675.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6536.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6481.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6540.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6539.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6676.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6794.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6768.jpg'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6465.JPG'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/sheep.jpg'},
        {width: 340, height: 340, className: 'square-image-340', altText:'', source:'./images/IMG_6801.JPG'}
      ]

class WeddingCeremony extends React.Component {

render() {
    return (
    <div id="ceremony-section" className="simplify">

        <h1>The Big Party Where We<br/>Happen to Get Married</h1>
        <h3 className="text-align-center">SATURDAY, JULY 18, 2015<br/>4:00 – 10:00</h3>
        <p className="text-align-center"><strong>Sun Harvest Farm</strong><br/><em>Sun Prairie, Wisconsin</em></p>
        <p className="text-align-center"><strong>ATTIRE</strong>: Shirts suggested, but not required. Heels, not so good. Think &#39;<em>farm casual</em>&#39;.</p>

        <Carousel data={party_image_data} image_width={340} images_on_screen={3} transition_time={4000}
                max_width={1020} carousel_height={340} extra_width={0}>
        </Carousel>

        <p><strong>Sun Harvest Farm</strong> is the home of Steve and Kathy Coleman (Dave&#39;s parents), Jeanne, Mark, Elliot, and Abby&nbsp;Lydon (Dave&#39;s sister, brother-in-law, nephew, and niece). We visited for the Fourth of July in 2013, and although we weren&#39;t engaged yet, we knew that <em>this</em> was where we wanted to celebrate with family and friends. We know you&#39;ll fall in love with it&nbsp;too.</p>
    </div>
    )
}
}

export default WeddingCeremony
