import React from "react";
import Carousel from '../components/carousel.js'


let food_image_data = [
    {width: 510, height: 340, className: 'image-510x340', altText:"CurdGirl.jpg", source:"./images/CurdGirl.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"Curds2.jpg", source:"./images/Curds2.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"", source:"./images/saved_resource(2)"},
    {width: 510, height: 340, className: 'image-510x340', altText:"BanzoCart.jpg", source:"./images/BanzoCart.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"BanzoPita.jpg", source:"./images/BanzoPita.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"UmamiCart.jpg", source:"./images/UmamiCart.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"PorkBuns.jpg", source:"./images/PorkBuns.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"UmamiDumplings.jpg", source:"./images/UmamiDumplings.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"BatchBakehouse.jpg", source:"./images/BatchBakehouse.jpg"},
    {width: 510, height: 340, className: 'image-510x340', altText:"Cupcake.png", source:"./images/Cupcake.png"},
    {width: 340, height: 340, className: 'image-510x340', altText:"BatchCookies.jpg", source:"./images/BatchCookies.jpg"}
]

class FoodTrucks extends React.Component {

render() {
    return (

  <div id="foodtrucks-section" className="alt-section">
        <div className="simplify">
            <h1 className="text-align-center">Fun Food Day</h1>
            <p>We met in DUMBO, Brooklyn where food trucks were our favorite lunch option. Madison also has a thriving food cart scene, so we’ve asked some of the local favorites to cater our party. We hope you&#39;ll enjoy this unique take on wedding fare.</p>
            <p className="text-align-center">Check out our lineup below...</p>

  <Carousel data={food_image_data} image_width={510} images_on_screen={2} transition_time={4000}
            max_width={1020} carousel_height={340} extra_width={0}>
  </Carousel>

<div className='food-truck-info'>
    <div id='food-truck-col1'>
    <h3 className='food-truck-title'><a className='food-truck-title' target="_blank" href="http://www.curdgirl.com/about.html" rel="noopener noreferrer">Curd Girl</a></h3>
    <p className='food-truck-content'>Curd Girl sources her curds from Maple Leaf Cheese Factory out of Monroe—one of Wisconsin’s best known cheese making communities and her hometown. <em>&#34;The best curdz that we have had in Madison, hands down. The numbers don’t lie. The Curd Girl knows curdz.&#34; </em>— chzcurds.com&nbsp;</p>
    <h3 className='food-truck-title'>The Smokin&#39; Cantina</h3>
    <p className='food-truck-content'><span>The popular pick is their “Bacon on a Stick” — a ridiculously large slab of thick-cut bacon speared onto a stick.&nbsp;</span><em>&#34;The Smokin&#39; Cantina </em>is<em> Bryan &#34;Bing&#34; Bingham, cooking up fantastic local sustainable food on an old school wood-fired smoker.&#34;</em>&nbsp;</p>
    <h3 className='food-truck-title'><a className='food-truck-title' target="_blank" href="http://banzomadison.com/" rel="noopener noreferrer">BANZO</a></h3>
    <p className='food-truck-content'>Owner, Netalee, learned to make hummus from her grandmother as a child in Israel. It&#39;s a two-day process and the end product is truly amazing.&nbsp;<em>"I have seen Madison&#39;s food cart future, and its name is Banzo." </em>— Linda Falkenstein, Isthmus</p>
    </div>
<div id='food-truck-col2'>
<h3 className='food-truck-title'><a className='food-truck-title' target="_blank" href="http://www.umamimadison.com/about.html" rel="noopener noreferrer">UMAMI</a></h3>
<p className='food-truck-content'>Ten years ago, native New Yorkers Randy Ng and Michael Ding were friends in Brooklyn who regularly played basketball together and daydreamed about opening a dumpling shop.&nbsp;<em>&#34;Sleek and savory Umami is worth the dry cleaning bill.&#34;&nbsp;</em>— Lindsay&nbsp;Christians,&nbsp;Madison.com</p>
<h3 className='food-truck-title'><a className='food-truck-title' target="_blank" href="http://batchbakehouse.com/" rel="noopener noreferrer">BATCH BAKEHOUSE</a></h3>
<p className='food-truck-content'>Batch Bakehouse makes bread and pastry the old-fashioned way: before dawn, from scratch, using local ingredients. Each piece is carefully crafted by hand.&nbsp;<em>&#34;Our mind is towards quality and doing what we have to do to get there. Our focus on local is more on our customers and getting to know them.&#34;</em>&nbsp;— head baker and part-owner Ian Garthwait</p>
<blockquote>We can&#39;t tell you what&nbsp;<strong>not</strong>&nbsp;to eat, but if you visit Dane County&#39;s&nbsp;Farmer&#39;s Market on the Capitol Square Saturday morning, maybe don&#39;t visit <em>these</em> carts so you&#39;ll have something new at the farm.&nbsp;</blockquote>
</div>
        </div>
      </div>
  </div>

        )
}
}
export default FoodTrucks
