import React from "react";
import {Helmet} from "react-helmet";
import WeddingHeader from '../components/weddingheader.js'
import WeddingCeremony from '../components/ceremony.js'
import FoodTrucks from '../components/foodtrucks.js'
import Afters from '../components/afters.js'
import Travel from '../components/travel.js'
import Thanks from '../components/thanks.js'


import '../wedding.css';

export default () =>     <div id="top">
    <Helmet>
        <title>EO & Dave</title>
        <link rel="canonical" href="https://eo-dave.com" />
        <link rel="icon" href="/images/favicon.ico" type="image/x-icon" />
        <link href='https://fonts.googleapis.com/css?family=Raleway:700' rel='stylesheet' type='text/css' />
        <link href='https://fonts.googleapis.com/css?family=Raleway:600' rel='stylesheet' type='text/css' />
        <link href='https://fonts.googleapis.com/css?family=Raleway:500' rel='stylesheet' type='text/css' />
        <link href='https://fonts.googleapis.com/css?family=Raleway:400' rel='stylesheet' type='text/css' />
        <link href='https://fonts.googleapis.com/css?family=Raleway:200' rel='stylesheet' type='text/css' />
        <link href='https://fonts.googleapis.com/css?family=Raleway:100' rel='stylesheet' type='text/css' />
    </Helmet>

    <div>

    <WeddingHeader />

      <div className="global-page-props clearfix">
        <main id="page">
          <div>
            <WeddingCeremony />

            <FoodTrucks />

            <Afters />

            <Travel />

            <Thanks />
          </div>
        </main>

        <footer>
            <div className="footer-spacing">
              <nav><div className="footer-text"><a href="#header">Back to Top</a></div>
              </nav>
            </div>
        </footer>
</div>
      </div>
    </div>;
