import React from "react";
import '../carousel.css';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      left_offset: 0,
      skip_next_tick: false,
      image_width: this.props.image_width,
      total_width: (this.props.data.length + 2) * this.props.image_width + this.props.extra_width,
      images_on_screen: this.props.images_on_screen,
      carousel_height: this.props.carousel_height,
      carousel_max_width: this.props.max_width,
      transition_time: this.props.transition_time,
      extra_width: this.props.extra_width,
      excess_width: this.props.extra_width % 340,
      data: this.props.data,
      left_justify: true
     };
     this.adjustNext = this.adjustNext.bind(this);
     this.adjustPrevious = this.adjustPrevious.bind(this);
  }

  adjustCarousel(step) {
    let new_position = this.state.position + step
    let num_images = this.props.data.length
    let excess_width = 0

    if (this.state.left_justify !== true) {
      num_images += + Math.floor(this.state.extra_width/340)
      excess_width = this.state.extra_width % 340
    }
    let rotate_point = num_images - 1
    if (new_position > rotate_point) {
      new_position = 0
    }
    if (new_position < 0) {
      new_position = rotate_point
    }

    let new_left_offset = -(new_position * this.state.image_width)
    if (new_position === rotate_point) {
      new_left_offset = new_left_offset - excess_width
    }

    if (this.state.left_justify) {
      let l_offset = 0
      for (let i=0; i < new_position; i++) {
        l_offset = l_offset + this.state.data[i]['width']
      }
      new_left_offset = 0 - l_offset
    }

    this.setState(prevState => ({
      position: new_position,
      left_offset: new_left_offset
    }));
  }

  adjustPrevious(e) {
    this.adjustCarousel(-1)
    this.setState(prevState => ({skip_next_tick: true}))
  }

  adjustNext(e) {
    this.adjustCarousel(1)
    this.setState(prevState => ({skip_next_tick: true}))
  }

  tick() {
    if (this.state.skip_next_tick) {
      this.setState({skip_next_tick: false})
    } else {
      this.adjustCarousel(1)
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), this.state.transition_time);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="carousel" style={{height: this.state.carousel_height + 'px', maxWidth: this.state.carousel_max_width + 'px'}} >
      <div className="carousel-block" >
        <div className="carousel-image-container">
          <div className="carousel-image-window" style={{width: this.state.total_width +'px', marginLeft: this.state.left_offset + 'px' }}>
            {this.state.data.map(function(row, index){
              return <img key={index.toString()} className={row['className']} alt={row['altText']} src={row['source']} />;
            })}
            <img key={this.state.data.length+1} className={this.state.data[0]['className']} alt={this.state.data[0]['altText']} src={this.state.data[0]['source']} />
            <img key={this.state.data.length+2} className={this.state.data[1]['className']} alt={this.state.data[1]['altText']} src={this.state.data[1]['source']} />
          </div>
        </div>
          <div>
              <div className="carousel-controls">
                <span className="previous" onClick={this.adjustPrevious}></span>
                <span className="next" onClick={this.adjustNext}></span>
              </div>
          </div>
      </div>
      </div>
    )
  }
}

export default Carousel
