import React from "react";
import Carousel from '../components/carousel.js'

let thanks_image_data = [
    {width: 340, height: 340, className:'square-image-340', altText:"P1060298.jpg", source:"./images/P1060298.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_0021.JPG", source:"./images/IMG_0021.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_1805.jpg", source:"./images/IMG_1805.jpg"},
    {width: 453, height: 340, className:'image-453x340', altText:"P1060906.JPG", source:"./images/P1060906.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_2307.jpg", source:"./images/IMG_2307.jpg"},
    {width: 510, height: 340, className:'image-510x340', altText:"P1080305.JPG", source:"./images/P1080305.JPG"},
    {width: 604, height: 340, className:'image-604x340', altText:"P1080025.JPG", source:"./images/P1080025.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_1603.jpg", source:"./images/IMG_1603.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"EO_DC_PR.jpg", source:"./images/EO_DC_PR.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_3354.jpg", source:"./images/IMG_3354.jpg"},
    {width: 453, height: 340, className:'image-453x340', altText:"IMG_2839.jpg", source:"./images/IMG_2839.jpg"},
    {width: 510, height: 340, className:'image-510x340', altText:"IMG_2812.jpg", source:"./images/IMG_2812.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"EO_DC.jpg", source:"./images/EO_DC.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_5609.JPG", source:"./images/IMG_5609.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_8429.JPG", source:"./images/IMG_8429.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_4595.JPG", source:"./images/IMG_4595.JPG"},
    {width: 453, height: 340, className:'image-453x340', altText:"IMG_6194.JPG", source:"./images/IMG_6194.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_0981.JPG", source:"./images/IMG_0981.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_0691.JPG", source:"./images/IMG_0691.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_4193.jpg", source:"./images/IMG_4193.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_5734.JPG", source:"./images/IMG_5734.JPG"},
    {width: 453, height: 340, className:'image-453x340', altText:"IMG_3174.jpg", source:"./images/IMG_3174.jpg"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_5867.JPG", source:"./images/IMG_5867.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_3628.JPG", source:"./images/IMG_3628.JPG"},
    {width: 340, height: 340, className:'square-image-340', altText:"IMG_0404.jpg", source:"./images/IMG_0404.jpg"}
]

class Thanks extends React.Component {

render() {
    return (
  <div id="shameless-slideshow-section">
    <div className="index-section-wrapper" >
        <div className="simplify">

        <h1>Thanks.</h1>
        <p>We want to thank everyone for being part of this special day and for
           all the love and support you&#39;ve given us throughout the years. Even
           if you are not able to attend, you will be with us in spirit. We&#39;re
           excited to start this next chapter together and we look forward to having
           many more good times with all of you.</p>

              <Carousel data={thanks_image_data} image_width={340} images_on_screen={3} transition_time={4000}
                        max_width={1020} carousel_height={340} extra_width={1056}>
              </Carousel>
        <p>In lieu of a gift, please consider making a charitable donation to
            to <a target="_blank" href="https://secure3.convio.net/fbnyc/site/Donation2?df_id=2380&amp;2380.donation=form1#.VMU3pmR4ptI" rel="noopener noreferrer" >Food Bank for New York City</a> or
            &nbsp;<a target="_blank" href="https://www.z2systems.com/np/clients/breakthrough/donation.jsp" rel="noopener noreferrer">Breakthrough</a>.
            Both missions are important to us. Dave volunteered in the Bronx
            feeding those in need and Elizabeth advocated for survivors of
            violence and sexual assault at various Queens hospitals.</p>

        <div className="side-image">
        <img alt="instagram" src="./images/saved_resource(3)" /><br/>
        <img alt="wedpics" src="./images/saved_resource(4)" />
        </div>
        <blockquote className='foo'>
          We are asking our guests to share their wonderful party pics with us via
          the <a target="_blank" href="https://www.wedpics.com/home" rel="noopener noreferrer">WedPics app</a> (Wedding ID: <strong>EODave</strong>).
          Everyone&#39;s got a smartphone these days, right? Oh, sorry Agnes. It&#39;s super
          easy to download the free app for both Apple and Android. Once you&#39;re in,
          you upload away (before, during, or after the big party). And for those of you
          who <strong>Instagram</strong>, we&#39;re going to be using the
          hashtag <strong>#EODave</strong>.
        </blockquote>

        </div>
    </div>
 </div>
    )
}
}
export default Thanks
